import React from 'react'
import { Link } from 'gatsby'
/*import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from "gatsby-image"
import { RiArrowRightSLine } from 'react-icons/ri';
*/
import Head from "../components-en/head"

import Layout from '../components-en/layout'
import Hero from '../components-en/hero'
import FullHero from '../components-en/fullhero'
import Money from '../components-en/money'
import heroImage from "../images/en/index_hero.png"
import borclanmak from "../images/en/borclanmak.svg"
import flyingLetter from "../images/flying_letter.svg"
import how1 from "../images/en/how_1.png"
import how2 from "../images/how_2.png"
import how3 from "../images/how_3.svg"
import how4 from "../images/how_4.svg"
import highlight1 from "../images/en/ne_icin_biriktiriyorsun.svg"
import highlight2 from "../images/en/hep_beraber_biriktirin.svg"
import highlight3 from "../images/en/hayallerini_finanse_et.svg"
import highlight4 from "../images/en/beklemede_kal.svg"
import hayalKiz from "../images/hayalin_ne.svg"
import birlikte from "../images/birlikte.png"
import lonca from "../images/lonca.png"
import digitalage from "../images/digitalage.png"
import webrazzi from "../images/webrazzi.png"
import entrecom from "../images/entrecom.png"
import boun from "../images/boun.png"

import '../styles/grid.scss'
import '../styles/index.scss'

const heroContent={
  title:"Social way of saving money",
  description:"Select a common goal and a monthly amount, save money with your friends or family members.",
  image:heroImage,
  trackLabel:"hero"
}

const fullHeroContent={
  title:"",
  description:"Çember, is an app to save money with your friends and family members. Users can save money by creating a circle for a common goal or amount in which they need financing. Each month, the agreed amount is sent to one of the users in circle, at the end of the circle everybody receives the total target amount.",
  image:borclanmak
}


const IndexPage = () => {
  /*
  const blogData = useStaticQuery(graphql`
        query{
          allMarkdownRemark(limit: 3, sort: {fields: frontmatter___date, order: DESC}) {
            edges {
              node {
                frontmatter{
                  title
                  date(formatString: "DD.MM.YYYY")
                  hashtag
                  summary
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields{
                  slug
                }
              }
            }
          }
        }          
    `)

*/


  return(
 
    <Layout >
      <Head title="Homepage" description="Çember is an app where users can save money as groups for a common goal. With Çember, users can set a goal, agree on an amount to pay monthly and pay this to one of the users in the group every month. At the end of circle, all users receive the total target amount." />
      <Hero title={heroContent.title}
      description={heroContent.description}
      image={heroContent.image} trackLabel={heroContent.trackLabel}/>  
      
      <Money></Money>

      <FullHero title={fullHeroContent.title}
      description={fullHeroContent.description}
      image={fullHeroContent.image}/>    

      <div  className="row ctaBanner">
        <div className="row">
          <div  className="col-3">
            <span data-sal-delay="300" data-sal="fade" className="flying-text blue-highlight hidden-sm">
              Join now!
            </span>
            <span className="flying-left hidden-sm">
              <img data-sal-delay="400" data-sal="fade" src={flyingLetter} alt="Join now"/>
            </span>
            </div>
            <div className="col-6">
              <div className="col-12 ">
                <h2 data-sal="slide-left">Want to join our community?</h2>
               </div>
             
               <div data-sal="slide-left" data-sal-delay="100" className="col-12">   
               <form name="davetiye-banner" action="success" method="post" netlify-honeypot="bot-field" data-netlify="true" >
                  <input type="hidden" name="form-name" value="davetiye-banner" />
                  <input required type="email" name="email" placeholder="Your e-mail" />
                  <button type="submit" data-sal="slide-left" data-sal-delay="200">Join</button>
                </form>
               </div>
               
            </div>
            <div className="col-3">

            </div>
        </div>
      </div>
      <div className="row section-title">
        <h1 data-sal-duration="400" data-sal-delay="200"  data-sal="slide-down"  >How can you save money?</h1>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal-duration="400"  data-sal="slide-right" data-sal-delay="200">Create a circle.</h1>
                <p data-sal-duration="400"  data-sal="slide-right" data-sal-delay="500">
                To start saving money, create your first circle and name it. Choose your monthly savings goal, how many people you want to save money with and also payment day. You can share your motivation of saving with other circle members.
                </p>
                <img data-sal-duration="400"  data-sal="slide-right" data-sal-delay="700" className="highlight-image" src={highlight1} alt="What are you saving for?" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img data-sal-duration="600"  data-sal="zoom-in" data-sal-delay="200" src={how1} alt="Create a circle"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img data-sal-duration="600"  data-sal="zoom-out" data-sal-delay="100" src={how2} alt="Invite your friends or family members."/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal-duration="400"  data-sal="slide-left" data-sal-delay="200">Invite your friends or family members.</h1>
                <p data-sal-duration="400" data-sal="slide-left" data-sal-delay="500">You can invite your friends, family members who share the same dream with you. You can invite as many people as you want. The more crowded the circle, the less you pay. </p>
                <img data-sal-duration="400" data-sal="zoom-in" data-sal-delay="700" className="highlight-image" src={highlight2} alt="Save together" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal-duration="400" data-sal="slide-right" data-sal-delay="200">Start saving.</h1>
                <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="500">Make your payments every month, take total amount when it’s your turn. Don't forget to share your joy with the circle members!</p>
                <img data-sal-duration="400" data-sal="zoom-in" data-sal-delay="700" className="highlight-image" src={highlight3} alt="Finance your dreams" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={how3} alt="Start saving"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row colored light-blue-bg">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img data-sal="zoom-in" data-sal-delay="200"  data-sal-duration="600"  src={how4} alt="Join Circles"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1 data-sal="slide-left" data-sal-delay="100" data-sal-duration="600" >Join Circles</h1>
                <p data-sal="slide-left" data-sal-delay="200"data-sal-duration="600" >People who have the same dream as you may created a circle before. Check what has been created before, join the one thats suits you best! </p>
                <img data-sal="slide-left" data-sal-delay="300" data-sal-duration="600"  className="highlight-image" src={highlight4} alt="Soon" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-7">
              <div className="hero-text">
                <h1 data-sal="slide-right" data-sal-duration="400">What is your dream?</h1>
                <div className="hayaller">
                    <button data-sal="zoom-in">Playstation 5  
                      <span   role="img" aria-label="emoji"  className="emoji">
                        🔫
                      </span>
                    </button>
                    <button data-sal="zoom-in" data-sal-delay="150">Car 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🚙
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="650">Paris Holiday 
                      <span role="img" aria-label="emoji"  className="emoji">
                      💭
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="350">Airpods 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎵
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="750">Master  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎓
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="250">Electric Scooter  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛴 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="300" >iPhone 
                      <span role="img" aria-label="emoji"  className="emoji">
                      📱 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="550" >Shopping 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛒 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="850" >World Tour 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🌎 
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="950" >Avocado Garden
                      <span role="img" aria-label="emoji"  className="emoji">
                      🥑
                      </span>
                    </button>
                    <button  data-sal="zoom-in" data-sal-delay="1000" >Wedding
                      <span role="img" aria-label="emoji" className="emoji">
                      👰🤵
                      </span>
                    </button>

                    

                </div>
              </div>
            </div>
            <div className="col-5 center">
             
              <img data-sal="zoom-in" data-sal-delay="200" data-sal-duration="400" src={hayalKiz} alt="What is your dream?"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row testimonials">
          <div className="row">
          <p data-sal="fade" data-sal-duration="400" className="center"> <span role="img" aria-label="heart">💙 💚 🖤</span> </p>
          </div>
          <div className="row">
            <div className="col-4">
                
                <p data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">“Organization of these circles to save was always my responsinbility. I was tired of reminding people every month, and Çember can do it for me now!“</p>
                <span data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">Ayla Karabağ</span>
            </div>
            <div className="col-4">
                
                <p data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">“With a group of friends I have met since college, we created a Çember and find an excuse to meet with them and save money together. “</p>
                <span data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">Melisa Özdilek</span>
            </div>
            <div className="col-4">
             
                <p data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">“Gamers need to update their hardware time to time so I buy new stuff for my PC. Saving with my friends with the same goal is fun. “</p>
                <span data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200">Tunç Deha Uluç</span>
            </div>
          </div>
      </div>

      <div className="row faq">
        <div className="container">
          <div className="row section-title">
            <h1 data-sal="slide-down" data-sal-duration="400" data-sal-delay="200">Frequently Asked Questions
              <span role="img" aria-label="post"> 
            🤔
              </span></h1>
          </div>
          <div className="row">
              <ul>
                <li>
                  <span data-sal="slide-right" data-sal-duration="400" data-sal-delay="200">What is Çember?</span>
                  <p data-sal="slide-left" data-sal-duration="400" data-sal-delay="400">Çember is an app where users can save money as groups for a common goal. With Çember, users can set a goal, agree on an amount to pay monthly and pay this to one of the users in the group every month. At the end of circle, all users receive the total target amount.</p>
                </li>
                <li>
                  <span data-sal="slide-right" data-sal-duration="400" data-sal-delay="600">How can I download the app?</span>
                  <p data-sal="slide-left" data-sal-duration="400" data-sal-delay="800">You can download the app from App Store or Play Store.</p>
                </li>
                <li>
                  <span data-sal="slide-right" data-sal-duration="400" data-sal-delay="800">How it works?</span>
                  <p data-sal="slide-left" data-sal-duration="400" data-sal-delay="1000">You invite anyone you’d like to save money together to the circle you created. When the target number of people is achieved, circle starts. Payment order is randomized. 
Çember will remind you how much and when to pay. You’ll receive notifications.</p>
                </li>
                <p>
                   <Link to="/en/help">All Questions</Link>
                </p>
              </ul>
          </div>
        </div>
      </div>

    

      

      <div className="row media light-gray-bg">
       <div className="hero-container">
        <div className="container">
          <div className="row center">
            <h3>Featured by</h3>
          </div>
          <div className="row">
            <ul>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://loncagirisim.com/" target="_blank"><img alt="Bizden bahsedenler" src={lonca}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://digitalage.com.tr/lonca-girisimcilik-merkezinin-besinci-donemine-secilen-9-girisim/" target="_blank"><img alt="Bizden bahsedenler" src={digitalage}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://webrazzi.com/2020/06/01/lonca-girisimcilik-merkezi-besinci-donem-girisimler/" target="_blank"><img alt="Bizden bahsedenler" src={webrazzi}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://www.linkedin.com/feed/update/urn%3Ali%3Aactivity%3A6673209389492633600/?actorCompanyId=53101797" target="_blank"><img alt="Bizden bahsedenler" src={entrecom}/></a></li>
              <li data-sal="zoom-in" data-sal-duration="400" data-sal-delay="200"><a rel="noreferrer" href="https://haberler.boun.edu.tr/tr/haber/altin-gununden-ilham-alan-projeye-buyuk-odul" target="_blank"><img alt="Bizden bahsedenler" src={boun}/></a></li>
            </ul>
          </div>
          <div className="row birlikte">
            <div className="col-6 center">
              <img data-sal="zoom-out" data-sal-duration="400" data-sal-delay="400" src={birlikte} alt="Birlikte Biriktiriyoruz"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1 >We’re saving together!</h1>
                <p>
                We developed Çember for you to finance your dream items, save and spend money without taking on debts. You are not alone!
                </p>
                <p >
                A traditional saving method which is familiar to all of us has become a better experience in digital. We’re saving together for our dreams.
                </p>
              </div>
            </div>
          </div>
        </div>  
      </div>
      </div>

   


    </Layout>
  )
}

export default IndexPage